import { Grid, Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { ReferralCouponDetailsRes } from '@calo/app-types';

import Layout from '../components/layout';
import Loader from '../components/loader';
import CouponDetails from './couponDetails';
import client from '../libs/clinet';

export const getCouponDetailsRequest = async (
  coupon: string | undefined
): Promise<ReferralCouponDetailsRes | undefined> => {
  if (coupon) {
    const res = await client.get('v1/referral/code-details', {
      params: {
        coupon,
      },
    });
    return res.data;
  } else {
    return undefined;
  }
};

const Home = () => {
  let { coupon } = useParams();
  coupon = coupon?.toUpperCase();

  const { data, error, isLoading } = useSWR<any>(
    coupon,
    getCouponDetailsRequest,
    {
      errorRetryCount: 0,
    }
  );

  return (
    <Layout>
      <Grid
        style={{
          height: '65vh',
        }}
        container
        direction="column"
        justifyItems="center"
        justifySelf="center"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading && <Loader />}
        {data && !error && coupon && (
          <CouponDetails couponData={data} coupon={coupon!} />
        )}
        {(!coupon || error) && (
          <Typography
            variant="h4"
            component="h4"
            style={{
              padding: '20px',
              textAlign: 'center',
              opacity: 1,
              fontWeight: 'bold',
            }}
          >
            Oops! Unable to find the coupon details.
            <br /> <Link href="https://www.calo.app">Contact our support</Link>
          </Typography>
        )}
        <img
          alt="logo"
          src="bg-img.png"
          style={{
            height: '300px',
            width: '300px',
            objectFit: 'fill',
            position: 'absolute',
            bottom: '5vh',
            left: '0',
            zIndex: -1,
            opacity: 0.2,
          }}
        />
      </Grid>
    </Layout>
  );
};

export default Home;
